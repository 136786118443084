import React, { useEffect, useState } from 'react'
import Logo from '../../asset/img/logo/logo.png'
import { Tabs, Tab, Modal } from 'react-bootstrap'
import axios from 'axios';
import Home from '../pages/Home';
import { Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
function Admin() {
    let { citypara } = useParams()
    
    const [city,setCity] = useState('Chandigarh')
    const isAuth = sessionStorage.getItem('isAuth');
    const [activekey, setActiveKey] = useState('main')
    const [error, setError] = useState('')
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)
    const [show4, setShow4] = useState(false)
    const [show5, setShow5] = useState(false)
    const [show6, setShow6] = useState(false)
    const [form, setForm] = useState({ image: null })
    const [submit, SetSubmit] = useState('Upload')
    const [member, setMember] = useState([])
    const [memberId, setMemberId] = useState(-1)
    const [errors, setErrors] = useState({});
    const [membertype, setMemberType] = useState({});
    const [btndisabled, setBtnDisabled] = useState(false);

    const [wordcountabout, setWordCountabout] = useState(0)
    const [wordcountheading, setWordCountheading] = useState(0)
    
    useEffect(() => {
        if (citypara) {
            setCity(citypara)
        } else {
            setCity('Chandigarh')
       } 
    },[])
    useEffect(() => {
        if (show) {
            // Add custom class when modal is open
            document.querySelector('.modal-backdrop')?.classList.add('custom-admin-backdrop');
        } else {
            // Remove class when modal is closed
            document.querySelector('.modal-backdrop')?.classList.remove('custom-admin-backdrop');
        }
    }, [show]);
    var formData = new FormData();
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            const file = files[0];
            if (file.size > 1 * 1024 * 1024) {
                alert('File size should not exceed 1MB');
                e.target.value = "";
                return;
            }
            setForm((prevForm) => ({
                ...prevForm,
                [name]: file,
            }));
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        let newErrors = { ...errors };
        if (name === 'vedio' ) {
            // Extract video ID using regex
            const regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
            const match = value.match(regex);
            if (match && match[1]) {
                const videoId = match[1];
                const filter = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&loop=1&playlist=${videoId}`;
                setForm({
                    ...form,
                    [name]: filter
                });
            } else {
                console.error('Invalid YouTube URL');
                setForm({
                    ...form,
                    [name]: ''
                });
            }
        } else {
            setForm({
                ...form,
                [name]: value
            });
        }

        if (name === 'about') {
  
            const wordCount = value.trim().length;
            setWordCountabout(wordCount);

            if (wordCount < 40 || wordCount > 80) {
                newErrors[name] = "About must contain at least 40-80 alphabets.";
                setBtnDisabled(false);
            } else {
                delete newErrors[name];
                setBtnDisabled(false);
            }
        }
        if (name === 'heading') {
            const charCount = value.trim().length;
            setWordCountheading(charCount); // Optional: if you want to display the character count
            if (charCount < 10 || charCount > 40) {
                newErrors[name] = "Heading must contain between 10-40 alphabets.";
                setBtnDisabled(false);
            } else {
                delete newErrors[name];
                setBtnDisabled(false);
            }
        }
        setErrors(newErrors);

    }


    const handleSubmit = async (e, id) => {
        e.preventDefault();

        // Show a processing state
        SetSubmit("Processing...");

        // Create `saveData` from items
        const saveData = items.map((item) => {
            const struggle_value = document.getElementsByName(item.name)[0];
            const event_value = document.getElementsByName(item.name)[1];

            return {
                type: item.type, // 'paragraph' or 'bullet'
                name: item.name, // Unique name (e.g., 'description_1', 'bullet_1')
                value: struggle_value.value ? struggle_value.value : event_value.value ? event_value.value :'', // User input value
            };
        });

     

        formData.append('image', form.image || ''); 
        formData.append('form[vedio]', form.vedio || ''); 
        formData.append('form[heading]', form.heading || '');
        formData.append('form[about]', form.about || '');

        // Append `saveData` to `FormData`
        saveData.forEach((item, index) => {
            formData.append(`savedata[${index}][type]`, item.type);
            formData.append(`savedata[${index}][name]`, item.name);
            formData.append(`savedata[${index}][value]`, item.value);
        });

        try {
            // Send POST request to the backend
            const response = await axios.post(
                `https://chdfront.co.in/backend/public/adminupdate/${id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            // Handle response
            if (response.data.status === 'success') {
                SetSubmit("Completed");
                setShow2(true);
                setTimeout(() => {
                    setShow2(false);
                    window.location.reload();
                }, 2000);
            } else {
                alert('Work Under Process Please Try Later');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };


    const handleisAuth = () => {
        if (isAuth) {
            setShow(false);
        } else {
            setShow(true);
        }
    }

    useEffect(() => {
        handleisAuth();
    }, [])
    const handleAuth = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const handleAuthsubmit = (e) => {
        e.preventDefault();
        if (form.password ===    "9814104562") {
            sessionStorage.setItem('isAuth', true)
            setShow(false)
        } else {
            setError("Password Do Not Matched !")
        }
    }

    const GetMember = () => {
        axios.get('https://chdfront.co.in/backend/public/getallmembers')
            .then((response) => {
                if (response.data.status == "success") {
                    console.log('get member', response.data.data);
                    setMember(response.data.data)
                } else {
                    setMember([])
                    alert('Something went wrong during form submission!');
                }
            })
            .catch((error) => {
                alert("inside error")
                console.error('Error submitting form data:', error);
                alert('Error submitting form data');
            });
    }

    useEffect(() => {
        GetMember()
    }, [])

    const handleApprove = () => {
        axios.post(`https://chdfront.co.in/backend/public/approvemember`, { 'id': memberId, 'membertype' : membertype })
            .then((response) => {

                if (response.data.status === "success") {
                    GetMember()
                    setShow3(false)
                    setShow2(true);
                } else {
                    alert('Approval Failed');
                }

            }).catch((error) => {
                alert('Something went wrong');
            })
    }

    const handleDelete = () => {
        axios.post(`https://chdfront.co.in/backend/public/deletemember`, { 'id': memberId })
            .then((response) => {

                if (response.data.status === "success") {
                    GetMember()
                    setShow5(false)
                    setShow2(true);
                } else {
                    alert('Approval Failed');
                }

            }).catch((error) => {
                alert('Something went wrong');
            })
    }







    const [items, setItems] = useState([]); // This will store both paragraphs and bullet points

    const handleAddParagraph = () => {
        // Add a new paragraph in the sequence
        setItems([...items, { type: 'paragraph', name: `description_${items.filter(item => item.type === 'paragraph').length + 1}` }]);
    };

    const handleAddBulletPoint = () => {
        // Add a new bullet point in the sequence
        setItems([...items, { type: 'bullet', name: `bullet_${items.filter(item => item.type === 'bullet').length + 1}` }]);
    };


 


    return (
        <>
            <header className='header-section2 padding-side  border bg-light'>

                    <div className='w-100 border-bottom p-2 mb-0  bg-red d-flex justify-content-between'>
                        <Dropdown className='' autoClose="outside">
                            <Dropdown.Toggle className=' bg-transparent  text-white fs-3 fw-bold border-0' id="dropdown-basic">
                                <i className="bi bi-geo-alt"></i> {city.charAt(0).toUpperCase() + city.slice(1)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='w-100'>
                                <Dropdown.Item className='fs-3 border-bottom py-2 ' onClick={() => { window.open('/backend/chandigarh') }}>Chandigarh</Dropdown.Item>
                                <Dropdown.Item className='fs-3 border-bottom py-2'>
                                    <Dropdown className=''>
                                        <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                            Haryana
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align="end" className='w-100'>
                                            <Dropdown.Item className='fs-3 border-bottom py-2' onClick={() => { window.open('/backend/ambala') }}>Ambala</Dropdown.Item>
                                            <Dropdown.Item className='fs-3 py-2' onClick={() => { window.open('/backend/punchkula') }}>Panchkula</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </Dropdown.Item>

                                <Dropdown.Item className='fs-3 py-2'>
                                    <Dropdown className=''>
                                        <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                            Punjab
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align="end" className='w-100'>
                                            <Dropdown.Item className='fs-3 border-bottom py-2' onClick={() => { window.open('/backend/amritsar') }}>Amritsar</Dropdown.Item>
                                            <Dropdown.Item className='fs-3 py-2' onClick={() => { window.open('/backend/mohali') }}>Mohali</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <h4 className='fs-3 text-white' ><i className='bi bi-envelope-fill me-2'></i>info@cnewsblast.com</h4>

                    </div>
  
  
                <div className="d-flex justify-content-end  border-bottom  position-relative">
                    <img src={Logo} className='image position-absolute  display-pc' />
                    <button className='btn bg-danger text-white fs-2-sm' type='button' onClick={() => { sessionStorage.clear(); window.location.reload() }}>Logout</button>
                </div>

           

            </header>
            <section className='section-margin-top  padding-side'>
                
                <div className="row g-3 my-3 ">
                    <h2 className='fs-1 fw-600'>{city.toUpperCase()} NEWS UPLOAD</h2>
                    <div className="col-6   px-2 bg-white" >
                        <Tabs id="uncontrolled-tab-example" activeKey={activekey}
                            onSelect={(key) => setActiveKey(key)} >
                            <Tab eventKey="main" title='BREAKING NEWS'>
                                <div className='border p-4 my-2 '>
                                    <form onSubmit={(e) => handleSubmit(e, 1)}>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <h4 className="fs-3 mb-2">Breaking News Heading</h4>
                                                <input type='text' className='form-control fs-3 py-2 my-2' placeholder='Vedio Heading' name='heading' onChange={handleChange} required />
                                            </div>
                                            <div className="col-12">
                                                <h4 className="fs-3 mb-2">Main NEWS Youtube Vedio Link</h4>
                                                <input className='form-control fs-3 py-2 my-2' placeholder='Enter Youtube Vedio Link' name='vedio' onChange={handleChange} required />
                                            </div>
                                            <div className="col-12">
                                                <button className='btn bg-red fs-2-sm' type='submit'>{submit}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Tab>
                            <Tab eventKey="struggle" title='NEWS UPLOAD'  >
                                <div className=' border p-4  '>
                                    {/* {JSON.stringify(form.image)} */}
                                    {/* {console.log(form.image)} */}
                                    <form onSubmit={(e) => handleSubmit(e, 2)}>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <h4 className="fs-3 mb-2">NEWS Category</h4>
                                                <select className='form-select fs-3 py-2 my-2' name='news_section' onChange={handleChange} required>
                                                    <option>Select News Category</option>
                                                    <option>Political</option>
                                                    <option>Crime</option>
                                                    <option>Travel</option>
                                                    <option>Immigration</option>
                                                    <option>Sports</option>
                                                    <option>Business</option>
                                                    <option>Events</option>
                                                    <option>Celebrities</option>
                                                </select>
                                            </div>
                                            <div className="col-12">
                                                <div className="d-flex justify-content-between">
                                                    <h4 className="fs-3 ">NEWS Heading</h4>
                                                    {errors.heading ? (
                                                        <small className="text-danger fs-4">{errors.heading}</small>
                                                    ) : <small className="text-white fs-4">Heading must contain at least 10-40 alphabets</small>}
                                                    <small className="text-gray fs-4">{wordcountheading}/40 alphabets</small>

                                                </div>
                                  
                                                <input type='text' className='form-control fs-3 py-2 my-2' placeholder='Heading Of Content' name='heading' onChange={handleChange} required />
                                         
                                            </div>
                                            <div className="col-12">
                                                <div className="d-flex justify-content-between">
                                                <h4 className="fs-3 ">NEWS Description</h4>
                                                    {errors.about ? (
                                                        <small className="text-danger fs-4">{errors.about}</small>
                                                    ) : <small className="text-white fs-4"> Description must contain at least 40-80 alphabets.</small>}
                                                    <small className="text-gray fs-4">{wordcountabout}/80 alphabets</small>
                                                </div>
                                                <input type='text' className='form-control fs-3 py-2 my-2' placeholder='Heading Of Content' name='about' onChange={handleChange} required />
                                
                                            </div>
                                            
                                            <div className="col-12">
                                                <div className="d-flex justify-content-start">
                                                    <button className="btn bg-green fs-3" type='button' onClick={handleAddParagraph}>
                                                        <i className="bi bi-plus-circle me-2"></i> Add Paragraph
                                                    </button>
                                                    <button className="btn bg-dark text-white fs-3 ms-2" type='button' onClick={handleAddBulletPoint}>
                                                        <i className="bi bi-plus-circle"></i> Add Bullet Points
                                                    </button>
                                                </div>
                                                <small className="text-dark  fs-4">Note : On Pages It Will Appear In Same Sequence As They Are Added</small>
                                            </div>

                                            <div className="col-12">
                                               
                                                {items.map((item, index) => (
                                                    <div key={index}>
                                                        {item.type === 'paragraph' && (
                                                         
                                                            <>
                                                            <h4 className="fs-2-sm mb-2">Paragraph </h4>
                                                            <textarea
                                                                className="form-control fs-3 mb-3"
                                                                name={item.name}
                                                                placeholder="Enter Paragraph"
                                                                onChange={handleChange}
                                                                ></textarea>
                                                            </>
                                                        )}
                                                        {item.type === 'bullet' && (
                                                            <>
                                                                <h4 className="fs-3 mb-2">Bullet Point </h4>
                                                            <input
                                                                type="text"
                                                                className="form-control fs-2-sm py-2 my-2"
                                                                placeholder="Enter Bullet Point"
                                                                name={item.name}
                                                                onChange={handleChange}
                                                                required
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                    
                                           
                                            <div className="col-12">
                                                <h4 className='fs-3 fw-600'>Upload Photo</h4>
                                                <input type="file" className='form-control py-2 fs-3 fw-600 ' placeholder='Enter First Name' name='image' onChange={handleFileChange} required />
                                                <h5 className='text-gray fs-5 mt-1' >Max Size 1MB Allowed</h5>
                                            </div>
                                            <div className="col-12">
                                                <h4 className="fs-3 mb-2">Upload NEWS Youtube Vedio Link</h4>
                                                <input className='form-control fs-3 py-2 my-2' placeholder='Enter Youtube Vedio Link' name='vedio' onChange={handleChange}  />
                                            </div>
                                            <div className="col-12">
                                                <button className='btn bg-red fs-2-sm' type='submit' disabled={btndisabled}>{submit}</button>
                                            </div>

                                        </div>

                                    </form>
                                  
                                </div>
                            </Tab>
                            <Tab eventKey="event" title='WEEKLY PROGRAM'  >
                                <div className='border p-4 '>
                                    <form onSubmit={(e) => handleSubmit(e, 3)}>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <div className="d-flex justify-content-between">
                                                <h4 className="fs-3 mb-2">Heading Of Program</h4>
                                                    {errors.heading ? (
                                                        <small className="text-danger fs-4">{errors.heading}</small>
                                                    ) : <small className="text-white fs-4">Heading must contain at least 10-40 alphabets</small>}
                                                    <small className="text-gray fs-4">{wordcountheading}/40 alphabets</small>

                                                </div>
                                                <input type='text' className='form-control fs-3 py-2 my-2' placeholder='Heading Of Program' name='heading' onChange={handleChange} required />
                                      
                                            </div>
                                            <div className="col-12">
                                                <div className="d-flex justify-content-between">
                                                <h4 className="fs-3 mb-2">Program Description</h4>
                                                    {errors.about ? (
                                                        <small className="text-danger fs-4">{errors.about}</small>
                                                    ) : <small className="text-white fs-4">Everything Looks Good !</small>}
                                                    <small className="text-gray fs-4">{wordcountabout}/80 alphabets</small>

                                                </div>
                                                <input type='text' className='form-control fs-3 py-2 my-2' placeholder='Heading Of Program' name='about' onChange={handleChange} required />
                                            </div>
                                            <div className="col-12">

                                                {items.map((item, index) => (
                                                    <div key={index}>
                                                        {item.type === 'paragraph' && (

                                                            <>
                                                                <h4 className="fs-3 mb-2">Paragraph </h4>
                                                                <textarea
                                                                    className="form-control fs-3 mb-3"
                                                                    name={item.name}
                                                                    placeholder="Enter Paragraph"
                                                                    onChange={handleChange}
                                                                ></textarea>
                                                            </>
                                                        )}
                                                        {item.type === 'bullet' && (
                                                            <>
                                                                <h4 className="fs-3 mb-2">Bullet Point </h4>
                                                                <input
                                                                    type="text"
                                                                    className="form-control fs-3 py-2 my-2"
                                                                    placeholder="Enter Bullet Point"
                                                                    name={item.name}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-12">
                                                <h4 className='fs-3 fw-600'>Upload Photo</h4>
                                                <input type="file" className='form-control py-2 fs-3 fw-600 ' placeholder='Enter First Name' name='image' onChange={handleFileChange} required />
                                                <h5 className='text-gray fs-5 mt-1' >Max Size 1MB Allowed</h5>
                                            </div>
                                            <div className="col-12">
                                                <h4 className="fs-3 mb-2">Upload Youtube Vedio Link</h4>
                                                <input className='form-control fs-3 py-2 my-2' placeholder='Enter Youtube Vedio Link' name='vedio' onChange={handleChange}  />
                                            </div>
                                            <div className="col-12">
                                                <button className='btn bg-red fs-3' type='submit' disabled={btndisabled}>{submit}</button>
                                            </div>

                                        </div>

                                    </form>
                                </div>

                            </Tab>
                  
                        </Tabs>
                    </div>
                    <div className="col-6 bg-white">
                         
                    </div>

                </div>
          
                <Modal show={show} size='lg' className='modal Admin-modal' backdropClassName="admin-modal-backdrop" id="admin-modal" centered >
                    <div className='h-100 d-flex justify-content-center flex-column '>
                        <div className='border border-green p-4  text-start w-100 ' >
                            <form onSubmit={handleAuthsubmit}>
                                <div className="d-flex justify-content-start px-0">
                                    <img src={Logo} className='image ' />
                                  
                                </div>
                                <div className='px-2'>
                                    <h1 className='mainlogo fw-800'>Chandigarh NEWS Blast NEWS Upload</h1>
                                    <input type='password' name='password' className='form-control fs-2-sm py-2 mt-2' placeholder='Enter Login Password' required onChange={handleAuth} />
                                    <h4 className='fs-4 text-danger'>{error}</h4>
                                    <button className='btn bg-red  py-2 mt-4 fs-2-sm text-white w-50 ' type='submit' >Submit</button>
                                </div>
                          
                            </form>
                        </div>
                    </div>
                </Modal>
                <Modal show={show2} onHide={() => { setShow2(false) }} size='lg' className='' centered>
                    <div className='h-100 d-flex justify-content-center flex-column '>
                        <div className='border border-green p-5  text-center w-100 ' >
                            {/* <img src={check} alt="" className='' height={80} /> */}
                            <h1 className='mainlogo fw-800'>Updated Successfully ! </h1>
                            <h5 className='fs-2-sm mt-2'>Thankyou</h5>
                            <button className='btn bg-red  py-2 mt-4 fs-4 text-white w-50 ' type='button' onClick={() => { setShow2(false) }}>close</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={show3} size='lg' className='' centered>
                    <div className='h-100 d-flex justify-content-center flex-column '>
                        <div className='border border-green p-5  text-center w-100 ' >
                            {/* {JSON.stringify(memberId)} */}
                            <h1 className='fs-2 mb-3'>Are You Sure Want To Approve ?</h1>
                            <div className="d-flex justify-content-center">
                                <button className='btn bg-danger text-white mx-2 fs-2-sm' onClick={() => { setShow3(false) }}>Cancel</button>
                                <button className='btn bg-red2 text-white mx-2 fs-2-sm' onClick={handleApprove}>Approve</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal show={show5} size='lg' className='' centered>
                    <div className='h-100 d-flex justify-content-center flex-column '>
                        <div className='border border-green p-5  text-center w-100 ' >
                            {/* {JSON.stringify(memberId)} */}
                            <h1 className='fs-2 mb-3'>Are You Sure Want To Delete ?</h1>
                            <div className="d-flex justify-content-center">
                                <button className='btn bg-danger text-white mx-2 fs-2-sm' onClick={() => { setShow5(false) }}>Cancel</button>
                                <button className='btn bg-red2 text-white mx-2 fs-2-sm' onClick={handleDelete}>Proceed</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal show={show4} onHide={()=>{setShow4(false)}} size='lg' className='membership-modal' centered>

                </Modal>
            </section>
        </>
    )
}

export default Admin
