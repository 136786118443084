import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo from '../../asset/img/logo/logo.jpg';
import NEWS4 from '../../asset/img/News/News4.jpg'
import NEWS2 from '../../asset/img/News/News2.jpg'
import NEWS1 from '../../asset/img/News/news1.jpg'
import NEWS3 from '../../asset/img/News/news3.jpg'
import { Dropdown } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
const Sec_1_Breaking_NEWS = () => {
    const sliderRef = useRef(null);
    const [index, setIndex] = useState(0);
    const [index2, setIndex2] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % contentPairs.length);
        }, 10000); // Change every 4 seconds

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex2((prevIndex) => (prevIndex + 1) % contentPairs2.length);
        }, 4000); // Change every 4 seconds

        return () => clearInterval(interval);
    }, []);

    const contentPairs = [
        { title: "Chandigarh NEWS Blast For Latest Update Coming Soon With Breaking NEWS" },
        { title: "Breaking: Punjab Announces New Development Plans" },
        { title: "Haryana Elections 2025: Latest Updates" },
        { title: "India's Economy Growth Hits Record High" }
    ];
    const contentPairs2 = [
        { title: "Breaking NEWS" },
        { title: "NEWS Blast" },

    ];

    const newsData = [
        {
            img: NEWS4,
            title: "थोड़ा सच, पूरा झूठ।",
            date: "Saturday",
            desc: "Program in Hindi",
        },
        {
            img: NEWS2,
            title: "ਫਿਰ ਸਰਕਾਏ ਖੈਂਦੀਆਂ ਨੇ।",
            date: "Friday NEWS",
            desc: "Program On Governance In Punjabi, Hindi Subtitle",
        },
        {
            img: NEWS1,
            title: "Comedy Show",
            date: "Exclusive On CNB",
            desc: "Program in Punjabi, Hindi",
        },
        {
            img: NEWS3,
            title: "Exclusive Podcast",
            date: "Friday Show",
            desc: "Insights, Interviews, and More",
        },
    ];

    const settings = {
        vertical: true,          // Enable vertical scrolling
        verticalSwiping: true,   // Allow vertical swipe
        slidesToShow: 2,         // Show 2 slides at a time
        slidesToScroll: 2,       // Slide up by 2 slides
        autoplay: true,          // Auto slide
        autoplaySpeed: 3000,     // Change every 3 seconds
        infinite: true,          // Infinite loop
        arrows: false,           // Hide navigation arrows
        dots: false,             // Hide dots
    };

    return (
        <>

            <section className='section-margin-top  padding-side  position-relative'>
                <div className='d-flex justify-content-between my-2 '>
                    <div className='d-sm-flex d-block w-sm-100'>
                        <AnimatePresence mode="wait">
                            <motion.h1
                                className="fs-2 bg-red   btn me-2 display-pc"
                                key={contentPairs2[index2].title}
                                initial={{ opacity: 0, rotateX: 90 }}
                                animate={{ opacity: 1, rotateX: 0 }}
                                exit={{ opacity: 0, rotateX: -90 }}
                                transition={{ duration: 1 }}
                            >
                                {contentPairs2[index2].title}
                            </motion.h1>
                        </AnimatePresence>
    
                        <div className='display-mobile mb-2'>
                            <div className='d-flex justify-content-between p-2 bg-red  w-100'>

                                <motion.div
                                    animate={{ scale: [1, 1.1, 1] }} // Zoom In (1.1) and Zoom Out (1)
                                    transition={{ duration: 1, repeat: Infinity, repeatType: "reverse" }} // Infinite animation
                                >
                                    <div className='fs-2 ' >Breaking NEWS </div>
                                </motion.div>
                                <div className='fs-2 '>CNB Exclusive</div>

                            </div>

                        </div>

                        <div className="news-container text-center px-1">
                            <AnimatePresence mode="wait">
                                <motion.h1
                                    className="text-dark fs-2-lg"
                                    key={contentPairs[index].title}
                                    initial={{ opacity: 0, rotateX: 90 }}
                                    animate={{ opacity: 1, rotateX: 0 }}
                                    exit={{ opacity: 0, rotateX: -90 }}
                                    transition={{ duration: 1 }}
                                >
                                    {contentPairs[index].title}
                                </motion.h1>
                            </AnimatePresence>
                        </div>
                    </div>
                    <button className='btn fs-2 bg-red pt-0 display-pc'>Login</button>
                </div>
                <div className="row ">
                    <div className="col-md-8 col-sm-12 card p-2  border-0">
                        <div id="carouselExampleCaptions" className="carousel slide">

                            <div className="carousel-inner">
                                <div className="carousel-item active" data-ds-interval="20000">

                                    <iframe
                                        className="w-100 news-iframe"
                                        src="https://www.youtube.com/embed/6lxqxpr-Leg?autoplay=1&mute=1&loop=1&playlist=6lxqxpr-Leg"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                    ></iframe>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="col-md-4 col-12 card p-2 border-0 position-relative" id='news_slider'>
                        <button className="btn bg-green fs-2-sm mb-2 position-absolute " onClick={() => sliderRef.current.slickPrev()} style={{ top: 0, left: "50%", transform: "translateX(-50%)",zIndex:1000 }}>
                            <i class="bi bi-chevron-up"></i>
                        </button>
                        <Slider {...settings} ref={sliderRef}>
                            {newsData.map((item, index) => (
                                <div key={index} className="position-relative">
                                    <img src={item.img} className="news-img w-100" alt="News" />
                                    <img src={logo} className="image-md position-absolute" style={{ left: 3, top: 3 }} alt="Logo" />
                                    <h2 className="heading fw-600 mb-2 p-1 text-white position-absolute popup-text w-100">
                                        {item.title}
                                    </h2>
                                    <div className="position-absolute w-100 bg-opacity p-2" style={{ left: 0, bottom: 0 }}>
                                        <i>
                                            <h2 className="fs-2 fw-600 p-1 text-white text-start">{item.date}</h2>
                                        </i>
                                        <i>
                                            <h2 className="fs-3 fw-600 mb-2 p-1 text-white text-start">{item.desc}</h2>
                                        </i>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        <button className="btn bg-green fs-2-sm mt-2 position-absolute" onClick={() => sliderRef.current.slickNext()} style={{ bottom: '1%', left: "50%", transform: "translateX(-50%)" }} >
                            <i class="bi bi-chevron-down"></i>
                        </button>
                    </div>
                    <div className="col-12 p-2 bg-red d-flex justify-content-end">
                        <Dropdown className='' autoClose="outside">
                            <motion.div
                                animate={{ scale: [1, 1.1, 1] }} // Zoom In (1.1) and Zoom Out (1)
                                transition={{ duration: 1, repeat: Infinity, repeatType: "reverse" }} // Infinite animation
                            >
                                <Dropdown.Toggle className=' bg-transparent  text-white fs-3 fw-bold border-0' id="dropdown-basic">
                                    <i className="bi bi-bookmark"></i> Classified
                                </Dropdown.Toggle>
                            </motion.div>


                            <Dropdown.Menu className='w-100'>
                                <Dropdown.Item className='fs-3 border-bottom py-2'>
                                    <Dropdown className='' autoClose="outside">
                                        <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                            Automobile
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align="end" className='w-100'>
                                            <Dropdown.Item  className='fs-3 border-bottom py-2'>
                                                Car For Sale
                                            </Dropdown.Item>

                                            <Dropdown.Item  className='fs-3 border-bottom py-2'>
                                                Bike For Sale
                                            </Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>

                                </Dropdown.Item>

                                <Dropdown.Item className='fs-3 border-bottom py-2'>
                                    <Dropdown className='' autoClose="outside">
                                        <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                            Immigration
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align="end" className='w-100'>
                                            <Dropdown.Item  className='fs-3 border-bottom py-2'>
                                                Attorney
                                            </Dropdown.Item>

                                            <Dropdown.Item  className='fs-3 border-bottom py-2'>
                                                Lawyers
                                            </Dropdown.Item>
                                            <Dropdown.Item  className='fs-3 border-bottom py-2'>
                                                Agent
                                            </Dropdown.Item>

                                            <Dropdown.Item  className='fs-3 border-bottom py-2'>
                                                Study Abroad
                                            </Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>

                                </Dropdown.Item>
                                <Dropdown.Item className='fs-3 border-bottom py-2'>
                                    <Dropdown className='' autoClose="outside">
                                        <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                            Matrimonial
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align="end" className=''>
                                            <Dropdown.Item  className='fs-3 border-bottom py-2'>
                                                Search Groom
                                            </Dropdown.Item>

                                            <Dropdown.Item  className='fs-3 border-bottom py-2'>
                                                Search Bride
                                            </Dropdown.Item>


                                        </Dropdown.Menu>
                                    </Dropdown>

                                </Dropdown.Item>
                                <Dropdown.Item className='fs-3 border-bottom py-2'>
                                    <Dropdown className='' autoClose="outside">
                                        <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                            Property
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align="end" className='w-100'>
                                            <Dropdown.Item  className='fs-3 border-bottom py-2'>
                                                <Dropdown className='' autoClose="outside">
                                                    <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                                        For Sale
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu align="end" className='w-100'>
                                                        <Dropdown.Item  className='fs-3 border-bottom py-2' onClick={()=>{navigate('/property/sale/chandigarh')}}>
                                                        Chandigarh 
                                                        </Dropdown.Item>
                                                        <Dropdown.Item  className='fs-3 border-bottom py-2' onClick={()=>{navigate('/property/sale/mohali')}}>
                                                        Mohali 
                                                        </Dropdown.Item>
                                                        <Dropdown.Item  className='fs-3 border-bottom py-2' onClick={()=>{navigate('/property/sale/puchkula')}}>
                                                        Punchkula 
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3" className='fs-3 py-2'>
                                                            <Dropdown className=''>
                                                                <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                                                    Punjab
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu align="end" className='w-100'>
                                                                    <Dropdown.Item className='fs-3 border-bottom py-2' onClick={() => { navigate('/property/sale/amritsar') }}>
                                                                     Amritsar
                                                                    </Dropdown.Item>
                                                                  
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3" className='fs-3 py-2'>
                                                            <Dropdown className=''>
                                                                <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                                                    Haryana
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu align="end" className='w-100'>
                                                                    <Dropdown.Item className='fs-3 border-bottom py-2' onClick={() => { navigate('/property/sale/ambala') }}>
                                                                     Ambala
                                                                    </Dropdown.Item>
                                                                  
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-3" className='fs-3 py-2'>
                                                <Dropdown className=''>
                                                    <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                                        For Rent
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu align="end" className='w-100'>
                                                        <Dropdown.Item className='fs-3 border-bottom py-2' onClick={() => { navigate('/property/rent/chandigarh') }}>
                                                            Chandigarh
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className='fs-3 border-bottom py-2' onClick={() => { navigate('/property/rent/mohali') }}>
                                                            Mohali
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className='fs-3 border-bottom py-2' onClick={() => { navigate('/property/rent/puchkula') }}>
                                                            Punchkula
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3" className='fs-3 py-2'>
                                                            <Dropdown className=''>
                                                                <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                                                    Punjab
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu align="end" className='w-100'>
                                                                    <Dropdown.Item className='fs-3 border-bottom py-2' onClick={() => { navigate('/property/rent/amritsar') }}>
                                                                        Amritsar
                                                                    </Dropdown.Item>

                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3" className='fs-3 py-2'>
                                                            <Dropdown className=''>
                                                                <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                                                    Haryana
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu align="end" className='w-100'>
                                                                    <Dropdown.Item className='fs-3 border-bottom py-2' onClick={() => { navigate('/property/rent/ambala') }}>
                                                                        Ambala
                                                                    </Dropdown.Item>

                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </Dropdown.Item>
                 
                                <Dropdown.Item className='fs-3  py-2'>
                                    <Dropdown className='' autoClose="outside">
                                        <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                            Travel
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align="end" className=''>
                                            <Dropdown.Item  className='fs-3 border-bottom py-2'>
                                            Air Tickting
                                            </Dropdown.Item>
                   
                                            <Dropdown.Item  className='fs-3 border-bottom py-2'>
                                            Domestic Tour Package
                                            </Dropdown.Item>
                                            <Dropdown.Item  className='fs-3 border-bottom py-2'>
                                            International Tour Package
                                            </Dropdown.Item>
                   
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </Dropdown.Item>
                           
                            </Dropdown.Menu>
                        </Dropdown>

                    </div>

                </div>

                <div
                    className={`btn bg-green text-white px-2 shadow shadow-lg  floating-help3  btn`}
                    style={{ zIndex: '1000' }} onClick={() => { navigate('/chandigarh-classified')}}>
                
                    <div className={`text-start`}>
                      <h3 className='fs-2-sm'>Chandigarh</h3>
                        <h3 className='fs-2-sm'>Classified</h3>
                    </div>
                </div>
                <div
                    className={`btn bg-green text-white px-2 shadow shadow-lg position-fixed floating-help display-pc `}
                    style={{ zIndex: '1000' }}>
                    <div className={`d-flex me-1 align-items-start`}>
                        <div className='d-flex flex-column'>
                            <i className="bi bi-facebook fs-2-sm my-1"></i>
                            <i className="bi bi-youtube fs-2-sm my-1"></i>
                            <i className="bi bi-instagram fs-2-sm my-1"></i>
                            <i className="bi bi-twitter fs-2-sm my-1"></i>
                            <i className="bi bi-pinterest fs-2-sm my-1"></i>
                        </div>
                    </div>
                </div>
                <div
                    className={`btn bg-green text-white px-2 shadow shadow-lg position-fixed floating-help2 display-mobile `}
                    style={{ zIndex: '1000' }}>
                    <div className={`d-flex me-1 align-items-start`}>
                        <div className='d-flex'>
                            <i className="bi bi-facebook fs-2-sm me-2"></i>
                            <i className="bi bi-youtube fs-2-sm me-2"></i>
                            <i className="bi bi-instagram fs-2-sm me-2"></i>
                            <i className="bi bi-twitter fs-2-sm me-2"></i>
                            <i className="bi bi-pinterest fs-2-sm "></i>
                        </div>
                    </div>
                </div>


            </section>


        </>
    )
}

export default Sec_1_Breaking_NEWS
