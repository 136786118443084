import React, { useEffect, useState, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import News2 from '../../asset/img/News/News4.jpg'
import logo from '../../asset/img/logo/logo.jpg'
import { useNavigate } from 'react-router-dom';
const Popup = () => {
    const [visible, setVisible] = useState(true);
    const [show, setShow] = useState(false);
    const hoverRef = useRef(false);
    const timeoutRef = useRef(null);
    const navigate = useNavigate()

    const startCloseTimer = () => {
        timeoutRef.current = setTimeout(() => {
            if (!hoverRef.current) setShow(false);
        }, 15000);
    };

    useEffect(() => {
        // Function to show the modal initially
        const openModal = () => {
            setShow(true);
            startCloseTimer();
        };

        // Initial timeout to show modal
        const showTimer = setTimeout(openModal, 4000);

        return () => {
            clearTimeout(showTimer);
            clearTimeout(timeoutRef.current);
        };
    }, []);

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal"
                centered
                onMouseEnter={() => {
                    hoverRef.current = true;
                    clearTimeout(timeoutRef.current); // Pause closing when hovered
                }}
                onMouseLeave={() => {
                    hoverRef.current = false;
                    startCloseTimer(); // Restart close timer when mouse leaves
                }}
            >
                <Modal.Body className="p-2">
                    <div className="d-sm-flex d-block justify-content-between align-items-start position-relative">
                        <div className="row">
                            <div className="col-sm-12 col-12 btn p-0" onClick={() => { window.open('/weekly-program/1'); }}>
                                <div className="px-2 text-center order-1">
                                    <h2 className="fs-2 fw-600 mb-2 p-1 text-dark">Watch Our Weekly Program</h2>
                                    <div className="position-relative">
                                        <img src={News2} className="news-iframe-2 popup-img" />
                                        <img src={logo} className="image-md position-absolute" style={{ left: 3, top: 3 }} />
                                        <h2 className="heading fw-600 mb-2 p-1 text-white position-absolute popup-text w-100">ਥੋੜਾ ਸਚ, ਪੂਰਾ ਝੂਠ</h2>
                                        <div className="position-absolute w-100 bg-opacity p-2" style={{ left: 0, bottom:0}}>
                                            <i><h2 className="fs-2-lg fw-600 p-1 text-white text-start">Saturday NEWS</h2></i>
                                            <i><h2 className="fs-3 fw-600 mb-2 p-1 text-white text-start">Watch At 4:00 PM Every Saturday</h2></i>
                                        </div>
                                    </div>
                                    <h2 className="fs-4 fw-600 mt-2 p-1 text-dark">Powered By</h2>
                                    <h2 className="fs-2 fw-600 mb-2 p-1 text-dark">Chandigarh NEWS Blast</h2>
                                </div>
                            </div>
                        </div>

                        <div className="position-absolute" style={{ right: 0, top: '-2px' }}>
                            <button className="btn bg-transparent p-0" onClick={() => setShow(false)}>
                                <i className="bi bi-x fs-1 fw-bold text-dark"></i>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Popup
