
import './App.css';
import Home from './component/pages/Home';
import { Routes, Route, BrowserRouter, useParams, Navigate } from 'react-router-dom';
import News from './component/pages/News';
import Backend from './component/Admin/Backend';
import Property_sale  from './component/pages/Property_sale';
import  Property_rent  from './component/pages/Property_rent';


const allowedCities = ['chandigarh', 'ambala', 'panchkula', 'amritsar', 'mohali', 'autralia', 'canada', 'newzealand', 'uk','usa'];

const CityRouteHandler = () => {
  const { citypara } = useParams()

  if (citypara === 'backend') {
    return <Navigate to="/backend/" replace />; 
  } else if (allowedCities.includes(citypara.toLowerCase())) {
    return <Home />; 
  } else {
    return <Navigate to="/pagenotfound" replace />; 
  }
};
function App() {
  return (
    < >
      <BrowserRouter>
        <Routes>
        
          <Route path='/' element={<Home />} />
          <Route path='/chandigarh-classified' element={<Home />} />
          <Route path='/:citypara' element={<CityRouteHandler />} />
          <Route path='/:slug-news/:id' element={<News />} />
          <Route path='/:slug/:id' element={<News />} />
          <Route path='/property/sale/:citypara' element={<Property_sale />} />
          <Route path='/property/rent/:citypara' element={<Property_rent />} />

          <Route path='/backend/:citypara' element={<Backend />} />
          <Route path='/backend/' element={<Backend />} />


        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
