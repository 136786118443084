import React from 'react'
import NEWS from '../../asset/img/News/news3.jpg'
import logo from '../../asset/img/logo/logo.jpg'
const Footer = () => {
  return (
    <>
      <footer className='section-6 bg-red d-flex padding-side' id='footer'>
        <div className="row g-3 w-100">
          <div className="col-12 p-2  ">
            {/* <h1 className='text-bold text-white fs-2 footerheading'>Chandigarh Residents Progressive Front</h1> */}
          </div>
          <div className="col-12 p-2 px-3">
            <div className="row g-3">
              <div className="col-lg-3 col-md-4 col-sm-4 col-12 order-sm-1 order-1 px-2">
                <div className='p-3 pb-1 px-0'>
                  <div className=' pb-2 border-gray'>
                    <p className='mb-2 fs-3 rounded rounded-1  py-1  fw-bold' style={{ textAlign: 'start' }}>Chandigarh NEWS Blast</p>
                    <p className='fs-4 fw-bold text-white ' style={{ textAlign: 'justify' }} >Chandigarh, the city beautiful, is buzzing with major developments across various sectors. From political upheavals to economic growth, the latest reports suggest significant changes impacting residents. Authorities have introduced new policies aimed at improving infrastructure and public services, ensuring a better quality of life for citizens.
                  In a major crackdown, law enforcement agencies have intensified efforts against rising crime, leading to multiple arrests and preventive measures being implemented across the city. </p>
                    <p className='fs-4 fw-bold text-white mt-3' style={{ textAlign: 'justify' }} >www.Cnewsblast.com</p>
                    <img src={logo} className=' image  display-pc' />
                  </div>

                </div>

              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 col-12 order-2  px-2 px-smm-1 ">
                <div className="p-2 px-2  px-smm-1">
                  <div className=' p-3 px-2 '>
                    <h4 className='text-white fs-3'>BREAKING NEWS</h4>
                    <div className="row g-3 my-2  w-100 ">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-3">
                            <img src={NEWS} className='me-1 img-fluid' />
                          </div>
                          <div className="col-9">
                            <div className='h-100 px-3 d-flex flex-column align-items-start justify-content-between'>
                              <h2 className="fs-3 fw-400">Chandigarh News Blast Breaking News</h2>
                              <h2 className="fs-3 text-white fw-light">March 12, 2025</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-3">
                            <img src={NEWS} className='me-1 img-fluid' />
                          </div>
                          <div className="col-9">
                            <div className='h-100 px-3 d-flex flex-column align-items-start justify-content-between'>
                              <h2 className="fs-3 fw-400">Chandigarh News Blast Breaking News</h2>
                              <h2 className="fs-3 text-white fw-light">March 12, 2025</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-3">
                            <img src={NEWS} className='me-1 img-fluid' />
                          </div>
                          <div className="col-9">
                            <div className='h-100 px-3 d-flex flex-column align-items-start justify-content-between'>
                              <h2 className="fs-3 fw-400">Chandigarh News Blast Breaking News</h2>
                              <h2 className="fs-3 text-white fw-light">March 12, 2025</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-3">
                            <img src={NEWS} className='me-1 img-fluid' />
                          </div>
                          <div className="col-9">
                            <div className='h-100 px-3 d-flex flex-column align-items-start justify-content-between'>
                              <h2 className="fs-3 fw-400">Chandigarh News Blast Breaking News</h2>
                              <h2 className="fs-3 text-white fw-light">March 12, 2025</h2>
                            </div>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                  {/* <h4 className='text-white fw-light fs-4 mt-3' style={{ textAlign: 'justify', wordSpacing: '-3px' }}>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quibusdam maxime cumque porro, laboriosam, ut quo rerum illum vero labore numquam odit voluptatem sequi tempore perferendis natus expedita? Voluptates, placeat ullam! Lorem ipsum, dolor sit amet consectetur atus expedita? Voluptates </h4> */}
                </div>

              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 col-12 order-3  px-0" >
                <div className='p-3 pb-4  border-gray'>
                  <h4 className='text-white fs-3'>WEEKLY UPDATES</h4>
                  <h3 className=' text-white  fs-4  px-0 py-1 btn w-100 py-0 my-0 text-start fw-bold'>Chandigarh NEWS Blast For Latest Update Coming Soon With Breaking NEWS</h3>
                  <div className="row g-2">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-3">
                          <img src={NEWS} className='me-1 img-fluid' />
                        </div>
                        <div className="col-9">
                          <div className='h-100 px-3 d-flex flex-column align-items-start justify-content-between'>
                            <h2 className="fs-3 fw-400">Chandigarh News Blast Breaking News</h2>
                            <h2 className="fs-3 text-white fw-light">March 12, 2025</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-3">
                          <img src={NEWS} className='me-1 img-fluid' />
                        </div>
                        <div className="col-9">
                          <div className='h-100 px-3 d-flex flex-column align-items-start justify-content-between'>
                            <h2 className="fs-3 fw-400">Chandigarh News Blast Breaking News</h2>
                            <h2 className="fs-3 text-white fw-light">March 12, 2025</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-3">
                          <img src={NEWS} className='me-1 img-fluid' />
                        </div>
                        <div className="col-9">
                          <div className='h-100 px-3 d-flex flex-column align-items-start justify-content-between'>
                            <h2 className="fs-3 fw-400">Chandigarh News Blast Breaking News</h2>
                            <h2 className="fs-3 text-white fw-light">March 12, 2025</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <form >
                      <h2 className='fs-3 fw-600 mb-1 text-white '>Subscribe Now</h2>
                      <div className="d-flex">
                        <input className='form-control fs-3 py-2' placeholder='Enter Email' name='email' required />
                        <button className='btn bg-orange border  w-25 fs-3 ms-2 py-2 text-white'type='submit' >Submit</button>
                      </div>
                    </form>
                  </div>

                  <div className='display-mobile mt-3'>
                    <div className='p-3 pb-4 px-5  border rounded rounded-3 bg-white '>
                      <h3 className='text-orange fw-800 mb-2 fs-3 px-0 py-1 btn w-100 py-0 my-0 text-start' >CONTACT</h3>

                      <div className="row g-2">
                        <div className="col-12">
                          <input type="text" className='form-control py-2 fs-3' placeholder='Name' name="name" />
                        </div>
                        <div className="col-12">
                          <input type="Email" className='form-control py-2 fs-3' placeholder='Email' name="name" />
                        </div>
                        <div className="col-12">
                          <input type="number" className='form-control py-2 fs-3' placeholder='Mobile No.' name="name" />
                        </div>
                        <div className="col-12">
                          <select className='form-select fs-3 border'>
                            <option defaultValue>Subject</option>
                            <option >Matrimonial</option>
                            <option >Magazine</option>
                            <option >Suggestions</option>
                          </select>
                        </div>
                        <div className="col-12">
                          <textarea className='form-control fs-3' placeholder='Enter Message'></textarea>
                        </div>
                        <div className="col-12">
                          <button className='btn fs-3 border bg-orange'>Submit</button>
                        </div>
                      </div>

                    </div>
                    <div className='d-flex justify-content-center my-4 mb-0'>
                      <img src={logo} className='image footer-logo rounded-3' />
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-3 col-sm-2 col-12 order-4 px-2 d-md-block d-sm-none d-none">
                <div className='p-3 pb-4 px-5  border rounded rounded-4 bg-white '>
                  <h3 className='text-orange fw-800 mb-2 fs-3 px-0 py-1 btn w-100 py-0 my-0 text-start' >CONTACT</h3>

                  <div className="row g-2">
                    <div className="col-12">
                      <input type="text" className='form-control py-2 fs-3' placeholder='Name' name="name" />
                    </div>
                    <div className="col-12">
                      <input type="Email" className='form-control py-2 fs-3' placeholder='Email' name="name" />
                    </div>
                    <div className="col-12">
                      <input type="number" className='form-control py-2 fs-3' placeholder='Mobile No.' name="name" />
                    </div>
                    <div className="col-12">
                      <select className='form-select fs-3 border'>
                        <option defaultValue>Subject</option>
                        <option >Matrimonial</option>
                        <option >Magazine</option>
                        <option >Suggestions</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <textarea className='form-control fs-3' placeholder='Enter Message'></textarea>
                    </div>
                    <div className="col-12">
                      <button className='btn fs-3 border bg-orange'>Submit</button>
                    </div>
                  </div>


                  {/* <div className="d-flex justify-content-start" >
                                    <i class="bi bi-facebook fs-2 mx-2"></i>
                                    <i class="bi bi-youtube fs-2 mx-2"></i>
                                    <i class="bi bi-instagram fs-2 mx-2"></i>
                                    <i class="bi bi-twitter fs-2 mx-2"></i>
                                    <i class="bi bi-pinterest fs-2 mx-2"></i>
                                    <i class="bi bi-telegram fs-2 mx-2"></i>
                                </div> */}
                </div>

              </div>


            </div>
          </div>

          <div className="col-12 p-2 px-3 px-2  border-gray d-sm-flex d-block justify-content-between pt-3 bg-dark-orange border-top">
            <h1 className=' text-white fs-5  pb-2'>Copyright © All rights reserved  by <span className='text-white'>Chandigarh NEWS Blast</span></h1>
            <h1 className=' text-white fs-5 '>Website designed and hosted by <span className='text-white'> PKS Infotech Pvt Ltd</span></h1>
          </div>
        </div>

      </footer>
          {/* <footer className='bg-red p-2 d-flex justify-content-between'>
              <h5 className='mb-0'>Copyright © All rights reserved by Chandigarh NEWS Blast</h5>
              <h5 className='mb-0'>Website designed and hosted by  PKS Infotech Pvt Ltd</h5>
          </footer>  */}
    </>
  )
}

export default Footer
