import React from 'react'
import News1 from '../../asset/img/News/news3.jpg'
import News2 from '../../asset/img/News/news1.jpg'
import News3 from '../../asset/img/News/News2.jpg'
import logo from '../../asset/img/logo/logo.png'
const Sec_2_News_cards = () => {


    return (
        <>
            <section className='padding-side mt-3 ' >
                <div className='my-3 px-2 d-flex justify-content-between' id='politics'>
                    <h4 className='fs-2-lg'>POLITICAL NEWS </h4>
                    <h4 className='fs-2-sm text-secondary'>latest update on 12-03-25</h4>
                </div>
                <div className="row border-bottom border-2 pb-3 g-3">
                    <div className="col-sm-4 ">
                        <div className='py-2 px-1 card bg-white'>

                            <iframe
                                className='w-100 news-iframe-2 '

                                src="https://www.youtube.com/embed/hRsmQS-voGU?autoplay=1&mute=1&loop=1&playlist=hRsmQS-voGU"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                    <div className="col-sm-8 px-1">
                        <div className="row g-3">
                            <div className="col-12  display-pc">
                                <div className="p-2  text-start">
                                    <h3 className='fs-2'>Recent NEWS</h3>
                                </div>
                            </div>
                            {[...Array(4)].map((item,index) => (
                                <div className=" col-sm-6  p-2 btn text-start display-pc" key={index} onClick={()=>{window.open(`/political-news/${index+1}`)}}>
                                    <div className='card p-2 shadow shadow-md rounded'>
                                        <div className="row bg-white g-2">
                                            <div className="col-8">
                                                <img src={logo} className="image-sm" alt="..." />
                                                <h4 className='fs-2-sm'>Chandigarh News Blast Breaking NEWS</h4>
                                                <h4 className='fs-4 text-secondary'>12 March 2025</h4>
                                                <div className="d-flex justify-content-start">
                                                    <i className="bi bi-hand-thumbs-up fs-2">10</i>
                                                    <i className="bi bi-hand-thumbs-down fs-2 ms-3">0</i>
                                                </div>
                                            </div>
                                            <div className="col-4 d-flex">
                                                <img src={News2} className="h-80 w-100 rounded " alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                       
                        </div>

                    </div>
                    {[...Array(3)].map((item,index) => (
                        <div className="col-sm-4 col-12 p-2 btn text-start  display-pc" key={index} onClick={() => { window.open(`/political-news/${index + 1}`) }}>
                            <div className='card p-2 px-3 shadow shadow-md rounded'>
                                <div className="row bg-white g-2">
                                    <div className="col-8">
                                        <img src={logo} className="image-sm" alt="..." />
                                        <h4 className='fs-2-sm'>Chandigarh News Blast Breaking News</h4>
                                        <h4 className='fs-4 text-secondary'>12 March 2025</h4>
                                        <div className="d-flex justify-content-start">
                                            <i className="bi bi-hand-thumbs-up fs-2-sm">10</i>
                                            <i className="bi bi-hand-thumbs-down fs-2-sm ms-3">0</i>
                                        </div>
                                    </div>
                                    <div className="col-4 d-flex">
                                        <img src={News1} className="h-80 w-100 rounded " alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="col-12  p-2">
                        <h4 className='fs-2 bg-red p-1'>Breaking NEWS : Chandigarh NEWS Blast For Latest Update Coming Soon With Breaking NEWS</h4>
                    </div>
         
        
                </div>

                <div className='border-bottom py-3' id='crime'>
                    <div className='my-3 px-2 d-flex justify-content-between'>
                        <h4 className='fs-2-lg'>CRIME  NEWS</h4>
                        <h4 className='fs-2-sm text-secondary'>latest update on 12-03-25</h4>
                    </div>

                    <div className="row g-2">
                        <div className="col-sm-4 ">
                            <div className='py-2 px-1 card bg-white'>

                                <iframe
                                    className='w-100 news-iframe-2'
    
                                    src="https://www.youtube.com/embed/hRsmQS-voGU?autoplay=1&mute=1&loop=1&playlist=hRsmQS-voGU"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin"
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                        <div className="col-sm-8 px-1">
                            <div className="row g-2">
                                <div className="col-12  display-pc">
                                    <div className="p-2  text-start d-flex justify-content-between">
                                        <h3 className='fs-2'>Recent NEWS</h3>
                                 
                                    </div>
                                </div>
                                {[...Array(4)].map((item,index) => (
                                    <div className=" col-sm-6  p-2 btn text-start  display-pc" key={index} onClick={() => { window.open(`/crime-news/${index + 1}`) }}>
                                        <div className='card p-2 shadow shadow-md rounded'>
                                            <div className="row bg-white g-2">
                                                <div className="col-8">
                                                    <img src={logo} className="image-sm" alt="..." />
                                                    <h4 className='fs-2-sm'>Chandigarh News Blast Breaking NEWS</h4>
                                                    <h4 className='fs-4 text-secondary'>12 March 2025</h4>
                                                    <div className="d-flex justify-content-start">
                                                        <i className="bi bi-hand-thumbs-up fs-2">10</i>
                                                        <i className="bi bi-hand-thumbs-down fs-2 ms-3">0</i>
                                                    </div>
                                                </div>
                                                <div className="col-4 d-flex">
                                                    <img src={News2} className="h-80 w-100 rounded " alt="..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {[...Array(3)].map((item,index) => (
                            <div className="col-sm-4 col-12 p-2 btn text-start  display-pc" key={index} onClick={() => { window.open(`/crime-news/${index + 1}`) }}>
                                <div className='card p-2 px-3 shadow shadow-md rounded'>
                                    <div className="row bg-white g-2">
                                        <div className="col-8">
                                            <img src={logo} className="image-sm" alt="..." />
                                            <h4 className='fs-2-sm'>Chandigarh News Blast Breaking News</h4>
                                            <h4 className='fs-4 text-secondary'>12 March 2025</h4>
                                            <div className="d-flex justify-content-start">
                                                <i className="bi bi-hand-thumbs-up fs-2-sm">10</i>
                                                <i className="bi bi-hand-thumbs-down fs-2-sm ms-3">0</i>
                                            </div>
                                        </div>
                                        <div className="col-4 d-flex">
                                            <img src={News1} className="h-80 w-100 rounded " alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
            
                    </div>
                </div>
                <div className='border-bottom py-3' id='travel'>
                    <div className='my-3 px-2 d-flex justify-content-between' >
                        <h4 className='fs-2-lg'>TRAVEL </h4>
                        <h4 className='fs-2-sm text-secondary'>latest update on 12-03-25</h4>
                    </div>

                    <div className="row g-2">
                        {[...Array(4)].map((item,index) => (
                            <div className="col-sm-3 p-2 btn text-start" key={index} onClick={() => { window.open(`/travel-news/${index + 1}`) }}>
                                <div class="card rounded shadow shadow-md position-relative">
                                    <img src={News3} class="card-img-top rounded" alt="..." />
                                    <div class="card-body">
                                        <h5 class="card-title fs-2-sm">Chandigarh News Blast News Latest Update</h5>
                                        <p class="card-text fs-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                        <a href="#" class="btn bg-red fs-3">Read more</a>
                                    </div>
                                    <img src={logo} class="image-sm rounded position-absolute" alt="..." />
                                </div>
                            </div> 
                        ))}
                        <div className="col-12  p-2">
                            <h4 className='fs-2 bg-red p-1'>Breaking NEWS : Chandigarh NEWS Blast For Latest Update Coming Soon With Breaking NEWS</h4>
                        </div>
                    </div>
                </div>
                <div className='border-bottom py-3' id='health'>
                    <div className='my-3 px-2 d-flex justify-content-between'>
                        <h4 className='fs-2-lg'>IMMIGRATION</h4>
                        <h4 className='fs-2-sm text-secondary'>latest update on 12-03-25</h4>
                    </div>

                    <div className="row g-2">
                        {[...Array(4)].map((item, index) => (
                            <div className="col-sm-6 p-2 " key={index} >
                                <div className="row border rounded p-1 g-2">
                                    <div className="col-sm-4 p-2">
                                        <img src={News3} class="card-img-top rounded" alt="..." />
                                    </div>
                                    <div className="col-sm-8 p-2">
                                        <h1 className='fs-2-sm'>Chandigarh NEWS Blast Latest Update</h1>
                                        <h4 className='fs-4 text-secondary'>12 March 2025</h4>
                                        <p className='fs-3 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat iusto, iste beatae illo inventore natus, totam enim sequi dolores optio ducimus molestiae ipsum nam nulla eveniet adipisci aut nobis dignissimos.</p>
                                        <p className='fs-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat iusto, iste beatae illo inventore natus, totam enim sequi dolores optio ducimus molestiae ipsum nam nulla eveniet adipisci aut nobis dignissimos.</p>
                                        <p className='fs-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat iusto, iste beatae illo inventore natus, totam enim sequi dolores optio ducimus molestiae ipsum nam nulla eveniet adipisci aut nobis dignissimos.</p>
                                        <a href="#" class="btn bg-red fs-3 btn " onClick={() => { window.open(`/immigration-news/${index + 1}`) }}>Read more</a>
                                    </div>
                               </div>
                            </div> 
                        ))}
                        <div className="col-12  p-2">
                            <h4 className='fs-2 bg-red p-1'>Breaking NEWS : Chandigarh NEWS Blast For Latest Update Coming Soon With Breaking NEWS</h4>
                        </div>
                    </div>
                </div>
                <div className='border-bottom py-3' id='sports'>
                    <div className='my-3 px-2 d-flex justify-content-between'>
                        <h4 className='fs-2-lg'>SPORTS  NEWS</h4>
                        <h4 className='fs-2-sm text-secondary'>latest update on 12-03-25</h4>
                    </div>

                    <div className="row g-2">
                        {[...Array(6)].map((item, index) => (
                            <div className="col-sm-4 col-12 p-2 btn text-start" key={index} onClick={() => { window.open(`/sports-news/${index + 1}`) }}>
                                <div className='card p-2 px-3 shadow shadow-md rounded'>
                                    <div className="row bg-white g-2">
                                        <div className="col-8">
                                            <img src={logo} className="image-sm" alt="..." />
                                            <h4 className='fs-2-sm'>Chandigarh News Blast Breaking News</h4>
                                            <h4 className='fs-4 text-secondary'>12 March 2025</h4>
                                            <div className="d-flex justify-content-start">
                                                <i className="bi bi-hand-thumbs-up fs-2-sm">10</i>
                                                <i className="bi bi-hand-thumbs-down fs-2-sm ms-3">0</i>
                                            </div>
                                        </div>
                                        <div className="col-4 d-flex">
                                            <img src={News1} className="h-80 w-100 rounded " alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
                <div className='border-bottom py-3' id='celebrities'>
                    <div className='my-3 px-2 d-flex justify-content-between'>
                        <h4 className='fs-2-lg'>CELEBRITIES  NEWS</h4>
                        <h4 className='fs-2-sm text-secondary'>latest update on 12-03-25</h4>
                    </div>
                    <div className="row g-2">
                        {[...Array(4)].map((item, index) => (
                            <div className="col-sm-3 p-2 btn text-start" key={index} onClick={() => { window.open(`/celebrities-news/${index + 1}`) }}>
                                <div class="card rounded shadow shadow-md position-relative">
                                    <img src={News3} class="card-img-top rounded" alt="..." />
                                    <div class="card-body">
                                        <h5 class="card-title fs-2-sm">Chandigarh News Blast News Latest Update</h5>
                                        <p class="card-text fs-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                        <a href="#" class="btn bg-red fs-3">Read more</a>
                                    </div>
                                    <img src={logo} class="image-sm rounded position-absolute" alt="..." />
                                </div>
                            </div>
                        ))}
               
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sec_2_News_cards
