import React from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'

import Sec_1_Breaking_news from '../common/Sec_1_Breaking_news'
import Sec_2_News_cards from '../common/Sec_2_News_cards';
import Popup from '../common/Popup';
const Home = () => {
  const settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    vertical: true,
    verticalSwiping: true,
  };
  return (
    <>
      <Header /> 
      <Sec_1_Breaking_news />
      <Sec_2_News_cards />
      <Footer />
      <Popup/>
    </>
  )
}

export default Home
