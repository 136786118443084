import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import Header from '../common/Header';
import NEWS3 from '../../asset/img/News/news3.jpg'
import News2 from '../../asset/img/News/News2.jpg'
import logo from '../../asset/img/logo/logo.jpg'
import { Dropdown } from 'react-bootstrap';
import Footer from '../common/Footer';

const News = () => {
     const [index, setIndex] = useState(0);
    const [display_news, seDisplayNews] = useState('d-none');
    const [readbtn, setReadbtn] = useState('Read More');
    
        useEffect(() => {
            const interval = setInterval(() => {
                setIndex((prevIndex) => (prevIndex + 1) % contentPairs.length);
            }, 10000); // Change every 4 seconds
    
            return () => clearInterval(interval);
        }, []);
    
        const contentPairs = [
            { title: "Chandigarh NEWS Blast For Latest Update Coming Soon With Breaking NEWS" },
            { title: "Breaking: Punjab Announces New Development Plans" },
            { title: "Haryana Elections 2025: Latest Updates" },
            { title: "India's Economy Growth Hits Record High" }
    ];
    
    const handleReadMore = () => {
        if (display_news == 'd-none') {
            seDisplayNews('d-block')
            setReadbtn('Read Less')
        } else {
            seDisplayNews('d-none')
            setReadbtn('Read More')
        }
    }
  return (
      <>
          <Header/>
          <section className='section-margin-top  padding-side  position-relative'>
              <div className='d-flex justify-content-between my-2 '>
                  <div className='d-sm-flex d-block w-sm-100'>

                      <h3 className='fs-2 bg-red   btn me-2 display-pc'>Breaking NEWS </h3>
                      <div className='display-mobile mb-2'>
                          <div className='d-flex justify-content-between p-2 bg-red  w-100'>

                              <motion.div
                                  animate={{ scale: [1, 1.1, 1] }} // Zoom In (1.1) and Zoom Out (1)
                                  transition={{ duration: 1, repeat: Infinity, repeatType: "reverse" }} // Infinite animation
                              >
                                  <div className='fs-2 ' >Breaking NEWS </div>
                              </motion.div>
                              <div className='fs-2 '>CNB Exclusive</div>

                          </div>

                      </div>

                      <div className="news-container text-center px-1">
                          <h1 className='fs-2-lg'>Chandigarh News Blast Heading</h1>
                      </div>
                  </div>
                  <button className='btn fs-2 bg-red pt-0 display-pc'>Login</button>
              </div>
              <div className="row ">
                  <div className="col-md-8 col-sm-12 card p-2  border-0">
                      <div id="carouselExampleCaptions" className="carousel slide">

                          <div className="carousel-inner">
                              <div className="carousel-item active" data-ds-interval="20000">

                                  <iframe
                                      className='w-100 news-iframe'

                                      src="https://www.youtube.com/embed/6lxqxpr-Leg?autoplay=1&mute=1&loop=1&playlist=6lxqxpr-Leg"
                                      title="YouTube video player"
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                      referrerpolicy="strict-origin-when-cross-origin"
                                      allowFullScreen>
                                  </iframe>
                              </div>

                          </div>

                      </div>
                  </div>
                  <div className="col-md-4 col-12  card p-2  border-0 display-pc">
                      <div id="carouselExampleCaptions2" className="carousel slide" >
                          <div className="carousel-indicators">
                              <button type="button" data-bs-target="#carouselExampleCaptions2"
                                  data-bs-slide-to="0" className="active" aria-current="true"
                                  aria-label="Slide 3"></button>
                              <button type="button" data-bs-target="#carouselExampleCaptions2"
                                  data-bs-slide-to="1" aria-label="Slide 2"></button>
                              <button type="button" data-bs-target="#carouselExampleCaptions2"
                                  data-bs-slide-to="2" aria-label="Slide 4"></button>
                          </div>
                          <div className="carousel-inner">
                              <div className="carousel-item active" data-ds-interval="200000">
                                  <img src={News2} className="news-img" alt="..." />
                                  <div class="carousel-caption ">
                                      <h5 className='fs-2'>Weekly Program</h5>
                                      <p className='fs-1 fw-bold text-center'>ਥੋੜਾ ਸਚ, ਪੂਰਾ ਝੂਠ</p>
                                  </div>
                              </div>
                              <div className="carousel-item" data-ds-interval="10000">
                                  <img src={News2} className="news-img" alt="..." />

                              </div>
                              <div className="carousel-item" data-ds-interval="10000">
                                  <img src={News2} className="news-img" alt="..." />

                              </div>
                          </div>
                          <button className="carousel-control-prev" type="button"
                              data-bs-target="#carouselExampleCaptions2" data-bs-slide="prev">
                              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span className="visually-hidden">Previous</span>
                          </button>
                          <button className="carousel-control-next" type="button"
                              data-bs-target="#carouselExampleCaptions2" data-bs-slide="next">
                              <span className="carousel-control-next-icon" aria-hidden="true"></span>
                              <span className="visually-hidden">Next</span>
                          </button>
                      </div>
                      <div id="carouselExampleCaptions3" className="carousel slide mt-3" >
                          <div className="carousel-indicators">
                              <button type="button" data-bs-target="#carouselExampleCaptions3"
                                  data-bs-slide-to="0" className="active" aria-current="true"
                                  aria-label="Slide 3"></button>
                              <button type="button" data-bs-target="#carouselExampleCaptions3"
                                  data-bs-slide-to="1" aria-label="Slide 2"></button>
                              <button type="button" data-bs-target="#carouselExampleCaptions3"
                                  data-bs-slide-to="2" aria-label="Slide 4"></button>
                          </div>
                          <div className="carousel-inner">
                              <div className="carousel-item active">
                                  <img src={NEWS3} className="news-img" alt="..." />

                              </div>
                              <div className="carousel-item">
                                  <img src={NEWS3} className="news-img" alt="..." />

                              </div>
                              <div className="carousel-item">
                                  <img src={NEWS3} className="news-img" alt="..." />

                              </div>
                          </div>
                          <button className="carousel-control-prev" type="button"
                              data-bs-target="#carouselExampleCaptions3" data-bs-slide="prev">
                              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span className="visually-hidden">Previous</span>
                          </button>
                          <button className="carousel-control-next" type="button"
                              data-bs-target="#carouselExampleCaptions3" data-bs-slide="next">
                              <span className="carousel-control-next-icon" aria-hidden="true"></span>
                              <span className="visually-hidden">Next</span>
                          </button>
                      </div>
                  </div>
                  <div className="col-12 p-2 bg-red d-flex justify-content-end">
                      <Dropdown className='' autoClose="outside">
                          <motion.div
                              animate={{ scale: [1, 1.1, 1] }} // Zoom In (1.1) and Zoom Out (1)
                              transition={{ duration: 1, repeat: Infinity, repeatType: "reverse" }} // Infinite animation
                          >
                              <Dropdown.Toggle className=' bg-transparent  text-white fs-3 fw-bold border-0' id="dropdown-basic">
                                  <i className="bi bi-bookmark"></i> Classified
                              </Dropdown.Toggle>
                          </motion.div>


                          <Dropdown.Menu className='w-100'>

                              <Dropdown.Item className='fs-3 border-bottom py-2'>
                                  <Dropdown className='' autoClose="outside">
                                      <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                          Property
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu align="end" className='w-100'>
                                          <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                              <Dropdown className='' autoClose="outside">
                                                  <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                                      For Sale
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu align="end" className='w-100'>
                                                      <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                                          Chandigarh
                                                      </Dropdown.Item>
                                                      <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                                          Mohali
                                                      </Dropdown.Item>
                                                      <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                                          PunchKula
                                                      </Dropdown.Item>
                                                      <Dropdown.Item href="#/action-3" className='fs-3 py-2'>
                                                          <Dropdown className=''>
                                                              <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                                                  Punjab
                                                              </Dropdown.Toggle>
                                                              <Dropdown.Menu align="end" className='w-100'>
                                                                  <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                                                      Amritsar
                                                                  </Dropdown.Item>

                                                              </Dropdown.Menu>
                                                          </Dropdown>

                                                      </Dropdown.Item>
                                                      <Dropdown.Item href="#/action-3" className='fs-3 py-2'>
                                                          <Dropdown className=''>
                                                              <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                                                  Haryana
                                                              </Dropdown.Toggle>
                                                              <Dropdown.Menu align="end" className='w-100'>
                                                                  <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                                                      Ambala
                                                                  </Dropdown.Item>

                                                              </Dropdown.Menu>
                                                          </Dropdown>

                                                      </Dropdown.Item>
                                                  </Dropdown.Menu>
                                              </Dropdown>

                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-3" className='fs-3 py-2'>
                                              <Dropdown className=''>
                                                  <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                                      For Rent
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu align="end" className='w-100'>
                                                      <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>


                                                      </Dropdown.Item>
                                                      <Dropdown.Item href="#/action-3" className='fs-3 py-2'>Panchkula</Dropdown.Item>
                                                  </Dropdown.Menu>
                                              </Dropdown>
                                          </Dropdown.Item>
                                      </Dropdown.Menu>
                                  </Dropdown>

                              </Dropdown.Item>
                              <Dropdown.Item className='fs-3 border-bottom py-2'>
                                  <Dropdown className='' autoClose="outside">
                                      <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                          Automobile
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu align="end" className='w-100'>
                                          <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                              Car For Sale
                                          </Dropdown.Item>

                                          <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                              Bike For Sale
                                          </Dropdown.Item>

                                      </Dropdown.Menu>
                                  </Dropdown>

                              </Dropdown.Item>
                              <Dropdown.Item className='fs-3 border-bottom py-2'>
                                  <Dropdown className='' autoClose="outside">
                                      <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                          Immigration
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu align="end" className='w-100'>
                                          <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                              Attorney
                                          </Dropdown.Item>

                                          <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                              Lawyers
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                              Agent
                                          </Dropdown.Item>

                                          <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                              Study Abroad
                                          </Dropdown.Item>

                                      </Dropdown.Menu>
                                  </Dropdown>

                              </Dropdown.Item>
                              <Dropdown.Item className='fs-3 border-bottom py-2'>
                                  <Dropdown className='' autoClose="outside">
                                      <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                          Travel
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu align="end" className=''>
                                          <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                              Air Tickting
                                          </Dropdown.Item>

                                          <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                              Domestic Tour Package
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                              International Tour Package
                                          </Dropdown.Item>

                                      </Dropdown.Menu>
                                  </Dropdown>

                              </Dropdown.Item>
                              <Dropdown.Item className='fs-3 border-bottom py-2'>
                                  <Dropdown className='' autoClose="outside">
                                      <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                          Matrimonial
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu align="end" className=''>
                                          <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                              Search Groom
                                          </Dropdown.Item>

                                          <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>
                                              Search Bride
                                          </Dropdown.Item>


                                      </Dropdown.Menu>
                                  </Dropdown>

                              </Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>

                  </div>
              </div>
              <div
                  className={`btn bg-green text-white px-2 shadow shadow-lg position-fixed floating-help display-pc `}
                  style={{ zIndex: '1000' }}>
                  <div className={`d-flex me-1 align-items-start`}>
                      <div className='d-flex flex-column'>
                          <i className="bi bi-facebook fs-2-sm my-1"></i>
                          <i className="bi bi-youtube fs-2-sm my-1"></i>
                          <i className="bi bi-instagram fs-2-sm my-1"></i>
                          <i className="bi bi-twitter fs-2-sm my-1"></i>
                          <i className="bi bi-pinterest fs-2-sm my-1"></i>
                      </div>
                  </div>
              </div>
              <div
                  className={`btn bg-green text-white px-2 shadow shadow-lg position-fixed floating-help2 display-mobile `}
                  style={{ zIndex: '1000' }}>
                  <div className={`d-flex me-1 align-items-start`}>
                      <div className='d-flex'>
                          <i className="bi bi-facebook fs-2-sm me-2"></i>
                          <i className="bi bi-youtube fs-2-sm me-2"></i>
                          <i className="bi bi-instagram fs-2-sm me-2"></i>
                          <i className="bi bi-twitter fs-2-sm me-2"></i>
                          <i className="bi bi-pinterest fs-2-sm "></i>
                      </div>
                  </div>
              </div>
          </section>
          <section className='padding-side mt-3'>
              <div className='row my-4'>
                  <div className="col-sm-8 px-2 " >
                      <div className="news-container text-start px-1">
                          <h1 className='heading'>Chandigarh News Blast Heading</h1>
                      </div>
                      <p className='fs-2 my-2'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum minima repellat, vitae ratione cupiditate consequatur sed voluptate architecto explicabo quidem modi tempora soluta sequi atque a officia, culpa inventore recusandae.</p>
                      <p className='fs-2 my-2'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum minima repellat, vitae ratione cupiditate consequatur sed voluptate architecto explicabo quidem modi tempora soluta sequi atque a officia, culpa inventore recusandae.</p>
                      <p className={`fs-2 my-2 ${display_news}`}>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum minima repellat, vitae ratione cupiditate consequatur sed voluptate architecto explicabo quidem modi tempora soluta sequi atque a officia, culpa inventore recusandae.</p>
                      <p className={`fs-2 my-2 ${display_news}`}>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum minima repellat, vitae ratione cupiditate consequatur sed voluptate architecto explicabo quidem modi tempora soluta sequi atque a officia, culpa inventore recusandae.</p>
                      <button className='btn bg-green fs-2 w-100 btn' onClick={handleReadMore}>{readbtn}</button>
                  </div>
              </div>
     
              <h2 className="fs-2-sm ">Recent News</h2>
              <div className="row">
                  {[...Array(6)].map(() => (
                      <div className=" col-sm-4  p-2">
                          <div className='card p-2 shadow shadow-md rounded'>
                              <div className="row bg-white ">
                                  <div className="col-8">
                                      <img src={logo} className="image-sm" alt="..." />
                                      <h4 className='fs-2-sm'>Chandigarh News Blast Breaking NEWS</h4>
                                      <h4 className='fs-4 text-secondary'>12 March 2025</h4>
                                      <div className="d-flex justify-content-start">
                                          <i className="bi bi-hand-thumbs-up fs-2">10</i>
                                          <i className="bi bi-hand-thumbs-down fs-2 ms-3">0</i>
                                      </div>
                                  </div>
                                  <div className="col-4 d-flex">
                                      <img src={News2} className="h-80 w-100 rounded " alt="..." />
                                  </div>
                              </div>
                          </div>
                      </div>
                  ))}

              </div>
          </section>
          <Footer/>
    </>
  )
}

export default News
