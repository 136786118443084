import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import { Dropdown } from 'react-bootstrap';
import property1 from '../../asset/img/Property/property1.jpeg'
import property4 from '../../asset/img/Property/property4.webp'
import property5 from '../../asset/img/Property/property5.webp'
import { useParams } from "react-router-dom";
import Footer from "../common/Footer";
 const Property_rent = () => {
    let { citypara } = useParams()
    const [cityname, setCityName] = useState('')
    useEffect(() => {
        setCityName(citypara)
    }, [citypara])
    const house = [
        { 'img': property1, 'price': '25000/month', 'negotiable': 'Negotiable', 'name': '4 BHK House For Rent', 'des': '1st floor, 1225 sq. feet super built up, 4 BHK + Store, 4 toilet, South West sun facing, dry utility balconies, covered car parking 2 cars, Scooter parking.', 'address': 'Sector 47D, Chandigarh, India' },
        { 'img': property1, 'price': '25000 /month', 'negotiable': 'Fixed', 'name': '2 BHK Flat For Rent', 'des': '1st floor, 1225 sq. feet super built up, 2 BHK + Store, 2 toilet, South West sun facing, dry utility balconies, covered car parking 2 cars, Scooter parking.', 'address': 'Sector 47D, Chandigarh, India' },
        { 'img': property1, 'price': '30000 /month', 'negotiable': 'Negotiable', 'name': '2 BHK Flat For Rent', 'des': '1st floor, 1225 sq. feet super built up, 2 BHK + Store, 2 toilet, South West sun facing, dry utility balconies, covered car parking 2 cars, Scooter parking.', 'address': 'Sector 47D, Chandigarh, India' },
        { 'img': property1, 'price': '30000 /month', 'negotiable': 'Negotiable', 'name': '2 BHK Flat For Rent', 'des': '1st floor, 1225 sq. feet super built up, 2 BHK + Store, 2 toilet, South West sun facing, dry utility balconies, covered car parking 2 cars, Scooter parking.', 'address': 'Sector 47D, Chandigarh, India' },
        { 'img': property1, 'price': '30000 /month', 'negotiable': 'Negotiable', 'name': '2 BHK Flat For Rent', 'des': '1st floor, 1225 sq. feet super built up, 2 BHK + Store, 2 toilet, South West sun facing, dry utility balconies, covered car parking 2 cars, Scooter parking.', 'address': 'Sector 47D, Chandigarh, India' },
        { 'img': property1, 'price': '30000 /month', 'negotiable': 'Negotiable', 'name': '2 BHK Flat For Rent', 'des': '1st floor, 1225 sq. feet super built up, 2 BHK + Store, 2 toilet, South West sun facing, dry utility balconies, covered car parking 2 cars, Scooter parking.', 'address': 'Sector 47D, Chandigarh, India' },
    ]

    return (
        <>
            <Header />
            <section className=" section-margin-top  padding-side bg-white">
                <div className='d-sm-flex justify-content-between align-items-center d-block w-sm-100 bg-red px-2'>
                    <h1 className="fs-2 text-white btn me-2 ">Property For Rent In {cityname.charAt(0).toUpperCase() + cityname.slice(1)}</h1>
                </div>
                <div className="row mt-2 ">
                    <div className="col-9">
                        <div className="row w-100 g-2">
                            {house.map((item, index) => (
                                <div className="col-sm-4 p-2" key={index}>
                                    <div class="card shadow shadow-md" >
                                        <img class="card-img-top" src={item.img} alt="Card image cap" />
                                        <div class="p-2 border-bottom ">
                                            <h5 class="card-title fs-2 fw-bold">{item.name}</h5>
                                        </div>
                                        <div class="p-2 border-bottom d-flex justify-content-between">
                                            <h5 class="card-title fs-2">Price {item.price}</h5>
                                            <h5 class="card-title fs-3 text-green">{item.negotiable}</h5>
                                        </div>
                                        <div className="p-2 border-bottom">
                                            <h1 class="fs-3">{item.des}</h1>
                                        </div>
                                        <div className="p-2 ">
                                            <h1 class="fs-3">  <i className="bi bi-geo-alt me-2"></i>{item.address}</h1>
                                        </div>
                                        <div className="card-footer bg-white">

                                            <h1 class="fs-3">Contact: +91 895895XXXX</h1>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className="col-3 bg-white p-2">
                        <img class="card-img-top" src={property4} alt="Card image cap" />
                        <img class="card-img-top mt-3" src={property5} alt="Card image cap" />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
};
export default Property_rent